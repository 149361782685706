/* global $ */
import BaseClass from '../base/baseClass';
import {Tooltip} from 'bootstrap';
import RetirementForm from './retirement-form';
import { init } from 'cookie-though';
import { onPreferencesChanged } from 'cookie-though';
import { show } from 'cookie-though';

class App extends BaseClass
{
    init()
    {
        $('body').css('opacity', 1);
        this.initTooltips();
        this.newsfilter();
        this.initStickyHeader();
        this.initSubmenu();
        this.initSVG();
        this.initForms();
        this.initCookiePreference();
        this.initShowPreference();
        this.checkPreferences();
        console.log('App initialized');
    }

    initCookiePreference() {
        var config = {
            "policies": [
                {
                    "id": "essential",
                    "label": "Essential Cookies",
                    "description": "These are cookies are necessary to provide an online service on a website or used solely to carry out or facilitate the transmission of communications over a network.",
                    "category": "essential",
                },
                {
                    "id": "analytics",
                    "label": "Analytics",
                    "category": "analytics",
                    "description": "Analytics cookies or performance cookies are used to track website visitors and their user behaviour. This data is then used to improve the way the website works and in turn, used to improve user experience.",
                }
            ],
            "essentialLabel": "Always on",
            "permissionLabels": {
                "accept": "ACCEPT",
                "acceptAll": "ACCEPT ALL",
                "decline": "DECLINE"
            },
            "cookiePreferenceKey": "cookie-preferences",
            "header": {
                "title": "Parkhead Housing Association",
                "subTitle": "This website uses cookies to ensure you get the best experience on our website. ",
                "description": "You can choose what cookies you want to keep but we reccomend to keep all cookies."
            },
            "cookiePolicy": {
                "url":"https://www.parkheadha.org.uk/privacy-policy",
                "label":"Read the full cookie declaration",
            },
            "customizeLabel": "Customize"
        };
        init(config);
        if( $('.housing-application').length || $('.transfer-application').length || $('.retirement-application').length) {
            $('.cookie-though').removeClass('visible').addClass('invisible');
        }
    }
    checkPreferences() {
        onPreferencesChanged(()=> {
            location.reload(true)
        })
    }
    initShowPreference() {
        $('#cookieprefshow').on('click', function(){
            show();
        });
    }
    initForms()
    {
        if ($('#formidable_retirement_form').length) {
            let form_id = $('#formidable_retirement_form').attr('id');
            let form_msg= form_id+'-messages';
            new RetirementForm(form_id, $('.'+form_msg));
        }
    }

    initTooltips()
    {
        $.each($('[data-bs-toggle="tooltip"]'), function (i, elem) {
            new Tooltip(elem);
        });
    }

    newsfilter()
    {
        $('.category-select').change(function(){
            var url =$(this).find(':selected').data('url');
            var value =$(this).find(':selected').val();
            var queryParams = new URLSearchParams(window.location.search);
            // Set new or modify existing parameter value. 
            queryParams.set("category", value);
            // Replace current querystring with the new one.
            history.replaceState(null, null, "?"+queryParams.toString());
            history.pushState(null, null, "?"+queryParams.toString());
            $.ajax({
                url:url,
                type:'GET', 
                success: function(data){
                    $('#news-items').html($(data).find('#news-items'));
                }
            });
        });
    }

    initStickyHeader() {
        var stickyHeader = function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 200) {
                $('#header').addClass('scrolled');
            } else {
                $('#header').removeClass('scrolled');
            }
        };
        stickyHeader();
        $(window).scroll(function() {
            stickyHeader();
        });
    }

    initSubmenu() {
        var submenu = function() {
            $(".nav-item.has-children").each(function(i){
                i++;
                $(this).children(".submenu-toggler").attr("data-bs-target","#submenu"+i);
                $(this).children(".submenu").attr("id","submenu"+i);
            })
        };
        submenu();
    }

    initSVG() {
        $('img.svg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
    
            $.get(imgURL, function(data) {
                var $svg = $(data).find('svg');

                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }

                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                $svg = $svg.removeAttr('xmlns:a');

                $img.replaceWith($svg);
    
            }, 'xml');
    
        });
    }
}

export default App;